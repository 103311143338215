import dayjs from 'dayjs';
import { generateSlug } from 'random-word-slugs';

const cleanInput = (value) => value.toLowerCase().trim();

const PT_START_DATE = '2022-02-23';
const EN_START_DATE = '2022-09-18';
const ES_START_DATE = '2023-05-26';

const GREEN_THRESHOLD = 300;
const YELLOW_THRESHOLD = 1500;

const randomTipDistance = (guessHistory) => {
  const maxDistance = GREEN_THRESHOLD - 1;
  let tipDistance = Math.floor(Math.random() * maxDistance - 1) + 1;

  if (guessHistory.length > 0) {
    const distances = guessHistory.map((guess) => guess[1]);
    while (distances.includes(tipDistance)) {
      tipDistance = Math.floor(Math.random() * maxDistance - 1) + 1;
    }
  }
  return tipDistance;
};

const nextTipDistance = (guessHistory) => {
  let tipDistance = GREEN_THRESHOLD - 1;
  let lowestDistance = tipDistance;

  if (guessHistory.length > 0) {
    const distances = guessHistory.map((guess) => guess[1]);
    lowestDistance = Math.min(...distances, lowestDistance);
    if (lowestDistance > 1) {
      tipDistance = lowestDistance - 1;
    } else {
      tipDistance = 2;
      while (distances.includes(tipDistance)) {
        tipDistance += 1;
      }
    }
  }

  return tipDistance;
};

const halfTipDistance = (guessHistory) => {
  let tipDistance = GREEN_THRESHOLD - 1;
  let lowestDistance = 2 * tipDistance;

  if (guessHistory.length > 0) {
    const distances = guessHistory.map((guess) => guess[1]);
    lowestDistance = Math.min(...distances, lowestDistance);
    if (lowestDistance > 1) {
      tipDistance = Math.floor(lowestDistance / 2);
    } else {
      tipDistance = 2;
      while (distances.includes(tipDistance)) {
        tipDistance += 1;
      }
    }
  }

  return tipDistance;
};

const getInitialTime = (language) => {
  let initialTime = dayjs(PT_START_DATE, 'YYYY-MM-DD').startOf('day');
  if (language === 'en') {
    initialTime = dayjs(EN_START_DATE, 'YYYY-MM-DD').startOf('day');
  }
  if (language === 'es') {
    initialTime = dayjs(ES_START_DATE, 'YYYY-MM-DD').startOf('day');
  }
  return initialTime;
};

const getCurrentTime = () => {
  if (process.env.NODE_ENV === 'production') {
    return dayjs();
  }
  return dayjs();
};

const getTodaysGameId = (language) => {
  const initialTime = getInitialTime(language);
  const currentTime = getCurrentTime().startOf('day');
  return currentTime.diff(initialTime, 'day');
};

const slugOptions = {
  format: 'camel',
  partsOfSpeech: ['adjective', 'noun'],
  categories: { noun: ['food', 'science', 'profession', 'technology'] },
};
const generateRandomName = () => {
  let words = generateSlug(2, slugOptions);
  while (words.length > 15) {
    words = generateSlug(2, slugOptions);
  }
  // Capitalize first letter
  return words.charAt(0).toUpperCase() + words.slice(1);
};

export {
  cleanInput,
  GREEN_THRESHOLD,
  YELLOW_THRESHOLD,
  randomTipDistance,
  nextTipDistance,
  halfTipDistance,
  getInitialTime,
  getCurrentTime,
  getTodaysGameId,
  generateRandomName,
};
