import React from 'react';
import { Row } from './Row';

function WordList({
  words,
  highlights = [],
  order = 'similarity',
  isMultiplayer = false,
}) {
  const sortedWords = words.slice();
  console.log('isMultiplayer', isMultiplayer);
  console.log('sortedWords', sortedWords);

  if (order === 'similarity') {
    if (isMultiplayer) {
      sortedWords.sort((a, b) => a.distance - b.distance);
    } else {
      sortedWords.sort((a, b) => a[1] - b[1]);
    }
  }

  return (
    <div className="guess-history">
      {sortedWords.map((item) => (
        <div>
          <Row
            key={!isMultiplayer ? item[0] : item?.lemma}
            word={!isMultiplayer ? item[0] : item?.lemma}
            distance={!isMultiplayer ? item[1] : item?.distance}
            highlight={highlights.includes(
              !isMultiplayer ? item[0] : item?.lemma
            )}
            owner={isMultiplayer ? item?.player?.name : null}
          />
        </div>
      ))}
    </div>
  );
}

export { WordList };
