import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

// import TogetherSocket from './utils/TogetherSocket';
import { Icons } from './components/Icons';
import { Modal } from './components/Modal';
import { LanguageTogether } from './components/modals/LanguageTogether';
import { useLocalStorage } from './useLocalStorage';

const validLanguages = ['en', 'es', 'pt'];

function TogetherScreen() {
  const passedState = useLocation().state;
  const lobbyErrorMsg = passedState?.errorMsg;
  const lobbyRoomName = passedState?.roomName ? passedState.roomName : '';
  const { i18n } = useTranslation();
  const [errorMsg, setErrorMsg] = useState('');
  const changeSystemsLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    dayjs.locale(newLanguage);
  };

  const { t } = useTranslation();
  const { language } = useParams();
  const navigate = useNavigate();

  const [isEditingNickname, setIsEditingNickname] = useState(false);
  const [nickname, setNickname] = useLocalStorage('nickname', '');
  const [, setReconnectSecret] = useLocalStorage('reconnectSecret', {});

  const [roomName, setRoomName] = useState(lobbyRoomName);

  const [shownModal, setShownModal] = useState('');

  function onClickCreateRoom() {
    if (nickname === '') {
      setErrorMsg('errorMsgNickname');
      return;
    }
    if (roomName === '') {
      setErrorMsg('errorMsgRoomName');
      return;
    }
    if (roomName.length < 3) {
      setErrorMsg('errorMsgRoomNameLength');
      return;
    }
    if (roomName.length > 20) {
      setErrorMsg('errorMsgRoomNameMaxLength');
      return;
    }
    setReconnectSecret({});
    navigate(`/${language}/together/${roomName}`, {
      state: { isCreating: true },
    });
  }

  function onClickJoinRoom() {
    if (nickname === '') {
      setErrorMsg('errorMsgNickname');
      return;
    }
    if (nickname.length > 15) {
      setErrorMsg('errorMsgNicknameLength');
      return;
    }
    if (roomName === '') {
      setErrorMsg('errorMsgRoomName');
      return;
    }
    if (roomName.length < 3) {
      setErrorMsg('errorMsgRoomNameLength');
      return;
    }
    if (roomName.length > 20) {
      setErrorMsg('errorMsgRoomNameMaxLength');
      return;
    }
    navigate(`/${language}/together/${roomName}`, {
      state: { isCreating: false },
    });
  }

  useEffect(() => {
    if (language && validLanguages.includes(language)) {
      changeSystemsLanguage(language);
      return;
    }
    const preferredLanguages = navigator.languages;
    console.log(preferredLanguages);
    if (!preferredLanguages || preferredLanguages.length === 0) {
      console.log('no preferred languages');
      navigate(`/${validLanguages[0]}/together`);
    }
    const foundPreferred = preferredLanguages.find((l) =>
      validLanguages.includes(l)
    );
    if (foundPreferred) {
      console.log('found preferred language', foundPreferred);
      let preferredLanguage;
      if (foundPreferred.includes('en')) {
        preferredLanguage = 'en';
      } else if (foundPreferred.includes('es')) {
        preferredLanguage = 'es';
      } else {
        preferredLanguage = 'pt';
      }
      navigate(`/${preferredLanguage}/together`);
    } else {
      console.log('no valid preferred language');
      navigate(`/${validLanguages[0]}/together`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <div className="wrapper top-ad-padding">
      <div className="title-together-wrapper">
        <h1 className="title-together">CONTEXTO</h1>
        <h1 className="subtitle-together">together</h1>
      </div>

      <div className="nickname-wrapper">
        <Icons.Person />
        {isEditingNickname ? (
          <input
            type="text"
            className="nickname"
            placeholder={t('inputPlaceholderNickname')}
            value={nickname}
            onChange={(e) => {
              const inputValue = e.target.value;
              const filteredValue = inputValue.replace(/[^a-zA-Z0-9]/g, '');
              setNickname(filteredValue);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') setIsEditingNickname(false);
            }}
            maxLength={15}
          />
        ) : (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <span className="nickname" onClick={() => setIsEditingNickname(true)}>
            {nickname === '' ? t('inputPlaceholderNickname') : nickname}
          </span>
        )}
        <button
          className="pen-button"
          type="button"
          onClick={() => setIsEditingNickname(!isEditingNickname)}
        >
          {!isEditingNickname && <Icons.Pencil />}
          {isEditingNickname && <Icons.Check />}
        </button>
      </div>

      <div className="create-room-wrapper">
        <form>
          <input
            className="word"
            type="text"
            name="word"
            placeholder={t('inputPlaceholderTogether')}
            value={roomName}
            onChange={(e) => {
              const inputValue = e.target.value;
              const filteredValue = inputValue.replace(/[^a-zA-Z0-9]/g, '');
              setRoomName(filteredValue);
            }}
            maxLength={20}
          />
        </form>
        <div className="flex-container">
          <button
            type="button"
            className="button-together"
            onClick={onClickCreateRoom}
          >
            <Icons.Plus />
            <span>{t('create')}</span>
          </button>
          <span
            style={{
              padding: '10px',
              alignContent: 'center',
              justifyContent: 'center',
            }}
          >
            or
          </span>
          <button
            type="button"
            className="button-together"
            onClick={onClickJoinRoom}
          >
            <Icons.Join />
            <span>{t('join')}</span>
          </button>
        </div>
        <div className="errorMsg">
          {errorMsg !== '' && <span>{t(errorMsg)}</span>}
          {lobbyErrorMsg !== '' && <span>{lobbyErrorMsg}</span>}
        </div>
      </div>

      <div className="language">
        <button
          type="button"
          className="language"
          onClick={() => setShownModal('settings')}
        >
          <Icons.Settings />
          <span style={{ paddingLeft: '6px' }}>{t('Settings')}</span>
        </button>
      </div>

      {shownModal === 'settings' && (
        <Modal onClose={() => setShownModal('')}>
          <LanguageTogether language={language} onLanguageChange={navigate} />
        </Modal>
      )}
    </div>
  );
}

export default TogetherScreen;
