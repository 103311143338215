/* eslint-disable class-methods-use-this */
import React from 'react';
import io from 'socket.io-client';
import useDataStore from './TogetherDataContext';
import { cleanInput } from './misc';
import TogetherLobby from '../TogetherLobby';

let socket = null;

const url = 'https://together.contexto.me/';

class TogetherSocketClass {
  constructor(storeActions) {
    this.storeActions = storeActions;
  }

  connect() {
    socket = io(url);
    socket.on('roomPlayerCount', (count) => {
      console.log('roomPlayerCount', count);
    });
    socket.on('guessResult', (result, guessCount) => {
      console.log('guessResult', result);
      this.storeActions.updateData({ result, guessCount }, 'guessResult');
    });
    socket.on('disconnect', () => {
      console.log('disconnect');
    });
    socket.on('connect', () => {
      this.storeActions.updateData({}, 'connect');
      console.log('connect');
    });
    socket.on('playerJoined', (data) => {
      console.log('playerJoined', data);
      this.storeActions.updateData(
        { player: data.player, playerCount: data.playerCount },
        'playerJoined'
      );
    });
    socket.on('playerLeft', (data) => {
      console.log('playerLeft', data);
      this.storeActions.updateData(
        { player: data.player, playerCount: data.playerCount },
        'playerLeft'
      );
    });
    socket.on('playerRenamed', (data) => {
      console.log('playerRenamed', data);
      this.storeActions.updateData(
        { player: data.player, playerCount: data.playerCount },
        'playerRenamed'
      );
    });
    socket.on('gameStarted', () => {
      console.log('gameStarted');
      this.storeActions.updateData({}, 'gameStarted');
    });
    socket.on('anotherGameStarted', (newGameId) => {
      console.log('anotherGameStarted');
      this.storeActions.updateData({ game: newGameId }, 'anotherGameStarted');
    });
    socket.on('error', (error) => {
      console.log('error', error);
      // addtrad
      // tem que substituir o valor de error que vem do servidor pela mensagem correta
      this.storeActions.updateData({ error }, 'error');
    });
  }

  isConnected() {
    return socket !== null && socket.connected;
  }

  disconnect() {
    if (this.isConnected()) {
      socket.disconnect();
    }
  }

  leaveRoom(roomName) {
    socket.emit('leaveRoom', roomName);
    socket.disconnect();
  }

  rename(name) {
    if (socket === null) {
      this.connect();
    }
    if (socket.connected === false) {
      console.log('Not connected');
    }
    console.log('rename name passed', name);
    return new Promise((resolve) => {
      socket.emit('rename', name, (result, uid) => {
        console.log('rename result', result);
        if (result) {
          console.log('rename successful');
          resolve(uid);
        } else {
          console.log('rename failed');
          resolve(null);
        }
      });
    });
  }

  createRoom(name, password, language) {
    return new Promise((resolve) => {
      console.log('create room', name, password, language);
      socket.emit(
        'createRoom',
        name,
        password,
        language,
        (created, joined, gameNumber, userSecret) => {
          console.log('createRoom result', created);
          if (created) {
            console.log('createRoom successful');
            // Perform additional actions if createRoom is successful
            if (joined) {
              console.log('Joined room:', joined);
              resolve({ gameNumber, userSecret });
            } else {
              console.log('Failed to join room');
              this.enterRoom(name, password);
              // addtrad
              resolve('JoinRoomFailed');
            }
          } else {
            console.log('createRoom failed', created, joined);
            // Perform additional actions if createRoom fails
            // addtrad
            resolve(!joined ? 'RoomAlreadyExists' : 'ErrorCreatingRoom');
          }
        }
      );
    });
  }

  enterRoom(name, password) {
    return new Promise((resolve) => {
      let roomData = {};
      socket.emit('enterRoom', name, password, (result, room, secret) => {
        console.log('enterRoom result', result, room.name);
        if (result) {
          roomData = room;
          console.log('enterRoom successful');
          resolve({ success: true, r: roomData, secret });
        } else {
          console.log('enterRoom failed', result, room, secret);
          resolve({ success: false, r: room });
        }
      });
    });
  }

  startGame(roomName) {
    console.log('startGame', roomName);
    socket.emit('startGame', roomName);
  }

  startAnotherGame(roomName) {
    socket.emit('startAnotherGame', roomName);
  }

  guessAttempt(guess, roomName) {
    socket.emit('guessAttempt', cleanInput(guess), roomName);
  }

  getGuessList(roomName) {
    return new Promise((resolve) => {
      console.log('getGuessList', roomName);
      socket.emit('getGuessList', roomName, (guessList) => {
        console.log('guesses retornadas', guessList);
        resolve(guessList);
      });
    });
  }

  getPlayersInRoom(roomName) {
    return new Promise((resolve) => {
      socket.emit('getPlayersInRoom', roomName, (playerList) => {
        resolve(playerList);
      });
    });
  }

  reconnectToRoom(roomName, secret) {
    return new Promise((resolve) => {
      console.log('trytoreconnectToRoom', roomName, secret);
      socket.emit('reconnectToRoom', roomName, secret, (result, data) => {
        console.log('reconnectToRoom result', result);
        if (result) {
          console.log('reconnectToRoom successful');
          resolve({ success: true, data });
        } else {
          console.log('reconnectToRoom failed');
          resolve({ success: false, data: null });
        }
      });
    });
  }

  checkIfRoomHasPassword(roomName) {
    return new Promise((resolve) => {
      console.log('checkIfRoomHasPassword', roomName);
      socket.emit('checkIfRoomHasPassword', roomName, (hasPassword) => {
        console.log('checkIfRoomHasPassword', hasPassword);
        resolve(hasPassword);
      });
    });
  }

  getNewLeader(roomName) {
    return new Promise((resolve) => {
      console.log('getNewLeader', roomName);
      socket.emit('getNewLeader', roomName, (newLeader) => {
        console.log('getNewLeader', newLeader);
        resolve(newLeader);
      });
    });
  }
}

function TogetherSocket() {
  console.log('TogetherSocket', socket);
  const storeActions = useDataStore()[1];

  const socketApi = new TogetherSocketClass(storeActions);

  return <TogetherLobby socketApi={socketApi} />;
}

export default TogetherSocket;
