import { createHook, createStore } from 'react-sweet-state';

// Define your initial state
const initialState = {
  data: {},
  type: '',
};

// Define your actions
const actions = {
  updateData:
    (data, type) =>
    ({ setState }) => {
      setState({ data, type });
    },
};

// Create the store
const TogetherDataContext = createStore({
  initialState,
  actions,
});

const useDataStore = createHook(TogetherDataContext);

export default useDataStore;
