import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useLocalStorage } from '../useLocalStorage';
import { Icons } from './Icons';
import { track } from '../utils/analytics';

const TOP_BANNER_ID = 'new-game-conexo-es';

function TopBanner({ onActionClick = () => {}, language }) {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [dismissed, setDismissed] = useLocalStorage('dismissed', '');

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 1000);
  }, []);

  const ignoreDismissed = false;
  const startDate = dayjs('2024-06-01').startOf('day');

  const showBanner =
    startDate.isBefore(dayjs()) &&
    isVisible &&
    language === 'es' &&
    (ignoreDismissed || dismissed !== TOP_BANNER_ID);

  const handleActionClick = () => {
    track.topBannerAction(language, TOP_BANNER_ID);
    onActionClick();
  };

  const handleCloseClick = () => {
    track.topBannerDismiss(language, TOP_BANNER_ID);
    setIsVisible(false);
    setDismissed(TOP_BANNER_ID);
  };

  return (
    <div className={`top-banner ${showBanner ? 'visible' : 'hidden'}`}>
      <div className="top-banner-text">
        ¡Ahora puedes jugar <b>CONEXO</b> en español! ¿Puedes conectar las
        palabras?
      </div>
      <div className="top-banner-buttons">
        <a
          href="https://conexo.ws/es"
          target="_blank"
          rel="noreferrer"
          className="button small"
          onClick={handleActionClick}
        >
          <Icons.ExternalLink />
          Jugar
        </a>
        <button
          type="button"
          className="button small subtle"
          onClick={handleCloseClick}
        >
          {t('topBanner.close')}
        </button>
      </div>
    </div>
  );
}

export default TopBanner;
