import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HomeScreen from './HomeScreen';
import TogetherScreen from './TogetherScreen';
import TogetherSocket from './utils/TogetherSocket';
import { useLocalStorage } from './useLocalStorage';
import { useGameState } from './useGameState';
import useThemeStore from './utils/ThemeStore';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomeScreen />,
  },
  {
    path: '/:language/together',
    element: <TogetherScreen />,
  },
  {
    path: '/:language/together/:game',
    element: <TogetherSocket />,
  },
  {
    path: '/together',
    element: <TogetherScreen />,
  },
]);

function App() {
  const { gameState: state, setGameState: setState } = useGameState();

  const stateTheme = state.theme;
  if (stateTheme) {
    setState({ ...state, theme: undefined });
  }

  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

  const initialTheme = stateTheme || (defaultDark ? 'dark' : 'light');

  const [storageTheme] = useLocalStorage('theme', initialTheme);

  const [, setTheme] = useThemeStore();

  useEffect(() => {
    console.log('Setting theme from storage:', storageTheme);
    setTheme.initializeTheme(storageTheme);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   // If you want your app to work offline and load faster, you can change
  //   // unregister() to register() below. Note this comes with some pitfalls.
  //   // Learn more about service workers: https://cra.link/PWA
  //   // serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
  //   ServiceWorkerRegistration.unregister();

  //   // if ((theme === undefined && defaultDark) || theme === 'dark') {
  //   //   document.documentElement.setAttribute('data-theme', 'dark');
  //   // }
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <RouterProvider router={router} />;
}

export default App;
