import React from 'react';

function RoomPassword({ isCreating, confirmValue, closeModal }) {
  console.log('RoomPassword', isCreating, confirmValue, closeModal);
  const [password, setPassword] = React.useState('');
  const [showRequired, setShowRequired] = React.useState(false);

  function handleConfirm() {
    if (!isCreating && password === '') {
      setShowRequired(true);
      return;
    }
    confirmValue(password);
    closeModal();
  }

  function handleCancel() {
    confirmValue('');
    closeModal();
  }

  return (
    <div>
      <div>
        <span>{isCreating ? 'Set Password' : 'Enter Password'}</span>
      </div>
      <div>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
      </div>
      {isCreating && (
        <div>
          <button type="button" onClick={handleConfirm}>
            Set
          </button>
          <button type="button" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      )}
      {!isCreating && (
        <button type="button" onClick={handleConfirm}>
          Enter
        </button>
      )}
      {showRequired && <div>Password is required</div>}
    </div>
  );
}

export { RoomPassword };
