/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons } from '../Icons';
import useThemeStore from '../../utils/ThemeStore';
import { useLocalStorage } from '../../useLocalStorage';

const styles = {
  item: {
    display: 'flex',
    margin: '20px 0',
  },
  itemName: {
    minWidth: '30%',
    paddingRight: 10,
  },
  itemOptions: {
    maxWidth: '70%',
  },
  container: {
    display: 'flex',
    cursor: 'pointer',
    paddingBottom: 10,
  },
  input: {
    paddingRight: 10,
  },
  description: {
    fontSize: 14,
    opacity: 0.8,
  },
};

function LanguageTogether({ language, onLanguageChange }) {
  const { t } = useTranslation();

  const [{ theme }, onThemeChange] = useThemeStore();
  const [localTheme, setLocalTheme] = useState(theme);
  const [, setStorageTheme] = useLocalStorage('theme');

  return (
    <div>
      <div>
        <div className="modal-title">
          <Icons.Language />
          <h2>{t('Language')}</h2>
        </div>

        <div style={styles.item}>
          <div style={styles.itemName}>{t('Language')}</div>
          <div style={styles.itemOptions}>
            <div
              style={styles.container}
              onClick={() => onLanguageChange('/pt/together')}
            >
              <div style={styles.input}>
                <input type="radio" checked={language === 'pt'} readOnly />
              </div>
              <div>
                <div>Português</div>
                <div style={styles.description} />
              </div>
            </div>
            <div
              style={styles.container}
              onClick={() => onLanguageChange('/en/together')}
            >
              <div style={styles.input}>
                <input type="radio" checked={language === 'en'} readOnly />
              </div>
              <div>
                <div>English</div>
                <div style={styles.description} />
              </div>
            </div>
            <div
              style={styles.container}
              onClick={() => onLanguageChange('/es/together')}
            >
              <div style={styles.input}>
                <input type="radio" checked={language === 'es'} readOnly />
              </div>
              <div>
                <div>Español</div>
                <div style={styles.description} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={styles.item}>
        <div style={styles.itemName}>{t('settingsModal.theme')}</div>
        <div style={styles.itemOptions}>
          <div
            style={styles.container}
            onClick={() => {
              onThemeChange.setTheme('light', setStorageTheme);
              setLocalTheme('light');
            }}
          >
            <div style={styles.input}>
              <input type="radio" checked={localTheme === 'light'} readOnly />
            </div>
            <div>
              <div>{t('settingsModal.light')}</div>
              <div style={styles.description} />
            </div>
          </div>
          <div
            style={styles.container}
            onClick={() => {
              onThemeChange.setTheme('dark', setStorageTheme);
              setLocalTheme('dark');
            }}
          >
            <div style={styles.input}>
              <input type="radio" checked={localTheme === 'dark'} readOnly />
            </div>
            <div>
              <div>{t('settingsModal.dark')}</div>
              <div style={styles.description} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { LanguageTogether };
