import React from 'react';

function PlayerList({ players, guessList }) {
  // count how many guesses each player has made
  const playerGuessCounts = {};
  guessList.forEach((guess) => {
    if (!playerGuessCounts[guess.player.uid]) {
      playerGuessCounts[guess.player.uid] = 0;
    }
    playerGuessCounts[guess.player.uid] += 1;
  });

  // find the guess with the smallest distance for each player
  const playerBestGuesses = {};
  guessList.forEach((guess) => {
    if (
      !playerBestGuesses[guess.player.uid] ||
      guess.distance < playerBestGuesses[guess.player.uid].distance
    ) {
      playerBestGuesses[guess.player.uid] = guess;
    }
  });

  return (
    <div>
      <div className="player-list">
        <span className="player-list-counter">
          {players.length}
          {players.length !== 1 ? ' players' : ' player'}
        </span>
        {players.map((player) => (
          <div key={player.uid} className="player-list-item">
            <span className="player-list-name">{player.name}</span>
            <span className="player-list-guess-number">
              {playerGuessCounts[player.uid] || 0}{' '}
              {playerGuessCounts[player.uid] === 1 ? 'guess' : 'guesses'}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export { PlayerList };
