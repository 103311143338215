import { createHook, createStore } from 'react-sweet-state';

// Initial state
const initialState = {
  theme: '',
};

// Actions
const actions = {
  initializeTheme:
    (initialValue) =>
    ({ setState }) => {
      setState({ theme: initialValue });
      document.documentElement.setAttribute('data-theme', initialValue);
    },
  setTheme:
    (theme, setStorageTheme) =>
    ({ setState }) => {
      setState({ theme });
      document.documentElement.setAttribute('data-theme', theme);
      setStorageTheme(theme);
    },
};

// Store
const ThemeStore = createStore({
  initialState,
  actions,
});

const useThemeStore = createHook(ThemeStore);

export default useThemeStore;
