import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Icons } from './components/Icons';
import { useLocalStorage } from './useLocalStorage';
import useDataStore from './utils/TogetherDataContext';
import { WordList } from './components/WordList';
import { cleanInput, generateRandomName } from './utils/misc';
import { Row } from './components/Row';
import { Modal } from './components/Modal';
import { RoomPassword } from './components/modals/RoomPassword';
import { PlayerList } from './components/PlayerList';

const timeoutRules = [
  { distance: 10, timeout: 4000 },
  { distance: 50, timeout: 2500 },
  { distance: 100, timeout: 1000 },
];

function TogetherLobby({ socketApi }) {
  const { t } = useTranslation();
  const [shownModal, setShownModal] = useState('');
  const passedState = useLocation().state;
  console.log('Passed state:', passedState);
  const isCreating = passedState ? passedState.isCreating : false;
  console.log('Is creating:', isCreating);

  const [reconnectSecret, setReconnectSecret] = useLocalStorage(
    'reconnectSecret',
    { isCreating: false }
  );
  window.history.replaceState({}, '');
  const navigate = useNavigate();
  const [{ data, type }, dataActions] = useDataStore();
  const [nickname, setNickname] = useLocalStorage('nickname', '');
  const [isEditingNickname, setIsEditingNickname] = useState(false);
  const [player, setPlayer] = useState({ name: '', uid: '' });
  const { game } = useParams();
  const { language } = useParams();
  const link = `https://contexto.me/${language}/together/${game}`;
  const defaultRoom = {
    game: -1,
    language: '',
    name: '',
    capacity: 50,
    players: [],
    password: '',
    guessList: [],
    leader: { name: null, uid: null },
    started: false,
  };
  const [highlight, setHighlight] = useState();
  const [room, setRoom] = useState({ ...defaultRoom, name: game });
  const [roomHasPassword, setRoomHasPassword] = useState('unknown');
  const [errorDisplay, setErrorDisplay] = useState('');
  const [won, setWon] = useState(false);
  const [passwordInput, setPasswordInput] = useState(null);
  const [showPlayerList, setShowPlayerList] = useState(false);
  const [copyButtonText, setCopyButtonText] = useState(t('copyLinkButton'));
  const [inputDisabledUntil, setInputDisabledUntil] = useState(0);
  const [word, setWord] = useState('');

  function disableInput(timeout) {
    if (timeout <= 0) {
      setInputDisabledUntil(Date.now() + 5000);
    }
    setInputDisabledUntil(Date.now() + timeout);
  }

  function reenableInput() {
    setInputDisabledUntil(0);
  }

  function backToTheLobby(error, message) {
    socketApi.disconnect();
    dataActions.updateData({}, '');
    if (error) {
      navigate(`/${language}/together`, {
        state: { errorMsg: message, roomName: room.name },
      });
    } else {
      navigate(`/${language}/together`);
    }
  }

  function checkIfWon(guesses) {
    const checkWon = guesses.some((g) => g.distance === 0);
    if (checkWon) {
      setWon(true);
    }
  }

  async function connectToRoom(name, password) {
    const renameResult = await socketApi.rename(nickname);
    if (!renameResult) {
      console.log('Failed to Join Room', renameResult);
      return;
    }
    setPlayer({ name: nickname, uid: renameResult });
    const result = await socketApi.enterRoom(name, password);
    console.log('Enter room result:', result);
    if (result.success) {
      setRoom(result.r);
      console.log('Entered room', result);
      checkIfWon(result.r.guessList);
      setReconnectSecret({ roomName: name, secret: result.secret });
    } else {
      // addtrad para result.r
      const errorMessageTrad = t(`error.${result.r}`);
      backToTheLobby(true, errorMessageTrad);
      console.log('Failed to enter room');
    }
  }

  function showPasswordPrompt() {
    setShownModal('password');
  }

  async function createRoom(name, password) {
    const renameResult = await socketApi.rename(nickname);
    if (!renameResult) {
      console.log('Failed to Create Room', renameResult);
      return;
    }
    console.log('Renamed player, uid:', renameResult);
    setPlayer({ name: nickname, uid: renameResult });
    const result = await socketApi.createRoom(name, password, language);
    if (typeof result === 'object' && result.gameNumber) {
      console.log('Room created');
      setRoom({
        ...defaultRoom,
        name,
        password,
        players: [{ name: nickname, uid: renameResult }],
        leader: { name: nickname, uid: renameResult },
        game: result.gameNumber,
      });
      console.log('Rewsulto:', result);
      setReconnectSecret({ roomName: name, secret: result.userSecret });
    } else {
      // addtrad para result
      // result: RoomAlreadyExists
      // error.${result} => error.RoomAlreadyExists
      backToTheLobby(true, t(`error.${result}`));
      console.log('Failed to create room', result);
    }
  }

  useEffect(() => {
    if (passwordInput === null) return;
    if (isCreating) {
      createRoom(game, passwordInput);
    }
    if (!isCreating) {
      connectToRoom(game, passwordInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordInput]);

  function checkIfAlreadyGuessed(newGuess) {
    return room?.guessList?.some((g) => g.lemma === newGuess);
  }

  function showDuplicateGuessError(g) {
    const wordData = room.guessList.find((w) => w.lemma === g);
    console.log('Word data:', wordData);
    const compositeErrorMessage = `${t('theWord')} ${g} ${t(
      'alreadyGuessed'
    )}. ${t('error.alreadyGuessedPosition')}: ${wordData.distance + 1}`;
    setHighlight(wordData);
    setErrorDisplay(compositeErrorMessage);
    reenableInput();
  }

  function processGuess(g) {
    setErrorDisplay('');
    if (g === '') {
      // addtrad
      setErrorDisplay(t('error.NoWordEntered'));
      setHighlight();
      reenableInput();
      return;
    }
    if (g === highlight?.lemma) {
      showDuplicateGuessError(g);
      return;
    }
    if (!room) {
      // addtrad
      setErrorDisplay(t('error.RoomNotFound'));
      setHighlight();
      reenableInput();
      return;
    }
    if (checkIfAlreadyGuessed(cleanInput(g))) {
      console.log('Already guessed:', g);
      showDuplicateGuessError(g);
      return;
    }

    socketApi.guessAttempt(g, room.name);
  }

  useEffect(() => {
    if (roomHasPassword === 'unknown') return;
    if (roomHasPassword === false) {
      connectToRoom(game, '');
    }
    if (roomHasPassword === true) {
      showPasswordPrompt();
    }
    if (roomHasPassword === 'roomNoExist') {
      // addtrad
      backToTheLobby(true, t('error.RoomNotFound'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomHasPassword]);

  function showInitialNicknamePrompt() {
    if (nickname !== '') return;
    const name = generateRandomName();
    if (name) {
      setNickname(name);
      setPlayer({ name });
    }
  }

  useEffect(() => {
    console.log('Connecting to server');
    socketApi.connect();
    showInitialNicknamePrompt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function resyncPlayersInRoom() {
    const playerList = await socketApi.getPlayersInRoom(room.name);
    console.log('Resyncing players:', playerList, room);
    setRoom({ ...room, players: playerList });
  }

  async function resyncGuessesInRoom() {
    const guessList = await socketApi.getGuessList(room.name);
    console.log('Resyncing guesses:', guessList);
    const newGuessWon = guessList.some((g) => g.distance === 0);
    if (newGuessWon) {
      setWon(true);
    }
    setRoom({ ...room, guessList });
  }

  async function reconnectToRoom() {
    console.log('Called Reconnecting to room:', reconnectSecret);
    const result = await socketApi.reconnectToRoom(
      reconnectSecret.roomName,
      reconnectSecret.secret
    );
    console.log('Reconnect result:', result);
    if (result.success) {
      const roomData = { ...result.data.roomCopy };
      setRoom(roomData);
      console.log('Reconnected to room:', roomData);
      setPlayer(result.data.playerData);
      setNickname(result.data.playerData.name);
      checkIfWon();
    } else {
      console.log('Failed to reconnect to room');
      setReconnectSecret({});
      socketApi.checkIfRoomHasPassword(game).then((roomPasswordResult) => {
        console.log('Room has password:', roomPasswordResult);
        setRoomHasPassword(roomPasswordResult);
      });
    }
  }

  function startGame() {
    if (room.leader.uid !== player.uid) {
      console.log('Not the leader');
      return;
    }
    console.log('Starting game:', room);
    socketApi.startGame(room.name);
  }

  function removePlayerWhoLeft(newLeader) {
    if (room.players.length - 1 !== data.playerCount) {
      resyncPlayersInRoom();
    } else {
      setRoom((r) => ({
        ...r,
        players: r.players.filter((p) => p.uid !== data.player.uid),
        leader: newLeader,
      }));
    }
  }

  useEffect(() => {
    console.log('Data:', data, 'Type:', type);
    console.log('room', room);
    if (type === '') return;

    if (type === 'playerJoined' && data.player?.uid !== player.uid) {
      console.log('Adding player to room:', data.player);
      if (room.players.length + 1 !== data.playerCount) {
        console.log('Resyncing players in room');
        resyncPlayersInRoom();
      } else {
        console.log('Adding player to room:', data.player);
        setRoom((r) => ({ ...r, players: [...r.players, data.player] }));
      }
    }

    if (type === 'guessResult') {
      if (room?.started === false) {
        setRoom({ ...room, started: true });
      }
      console.log('Guess arrived:', data);
      if (
        (!room.guessList && data.guessCount > 1) ||
        room.guessList.length + 1 !== data.guessCount
      ) {
        console.log('Need to resync guesses in room');
        resyncGuessesInRoom();
      } else {
        console.log(
          'Adding guess to list:',
          data.result,
          data.result.player.uid,
          player.uid
        );
        setRoom((r) => ({ ...r, guessList: [...r.guessList, data.result] }));
      }
      if (data.result.player.uid === player.uid) {
        setHighlight(data.result);
        const { distance } = data.result;
        const timeout = timeoutRules.find(
          (r) => r.distance >= distance
        )?.timeout;
        console.log('Timeout:', timeout);
        setWord('');
        if (timeout) {
          disableInput(timeout);
        } else {
          reenableInput();
        }
      }
      if (data.result.distance === 0) {
        setWon(true);
      }
    }

    if (type === 'playerLeft') {
      if (
        data.player.uid === room.leader.uid ||
        !room.players.includes(data.player)
      ) {
        socketApi.getNewLeader(room.name).then((newLeader) => {
          removePlayerWhoLeft(newLeader);
        });
      }
      removePlayerWhoLeft(room.leader);
    }

    if (type === 'playerRenamed') {
      if (data.playerCount === room.players.length) {
        const renamedPlayer = room.players.find(
          (p) => p.uid === data.player.uid
        );
        renamedPlayer.name = data.player.name;
        setRoom({ ...room, players: [...room.players] });
        const updatedGuessList = room.guessList.map((g) => {
          if (g.player.uid === data.player.uid) {
            return { ...g, player: { ...g.player, name: data.player.name } };
          }
          return g;
        });
        setRoom({ ...room, guessList: updatedGuessList });
      } else {
        resyncPlayersInRoom();
        resyncGuessesInRoom();
      }
    }

    if (type === 'gameStarted') {
      setRoom({ ...room, started: true });
    }

    if (type === 'anotherGameStarted') {
      setRoom({ ...room, game: data.game, started: true, guessList: [] });
      setWon(false);
      setHighlight();
    }

    if (type === 'connect') {
      console.log('Connected to server!!!!', reconnectSecret, game, isCreating);
      if (isCreating) {
        setShownModal('password');
      } else if (
        reconnectSecret?.roomName === game &&
        reconnectSecret?.secret
      ) {
        console.log('Reconnecting to room:', reconnectSecret);
        reconnectToRoom();
      } else
        socketApi.checkIfRoomHasPassword(game).then((result) => {
          console.log('Room has password:', result);
          setRoomHasPassword(result);
        });
    }

    if (type === 'error') {
      console.log('Error:', data);
      if (typeof data.error === 'string') {
        if (data.error === 'ErrorAlreadyGuessed') {
          showDuplicateGuessError(word);
          setWord('');
        } else {
          setHighlight();
          setErrorDisplay(t(`error.${data.error}`));
        }
      } else if (data.error?.error) {
        setHighlight();
        setErrorDisplay(t(`error.${data.error.error}`));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, type]);

  function copyLink() {
    navigator.clipboard.writeText(link);
    setCopyButtonText(t('end.copied'));
    setTimeout(() => setCopyButtonText(t('copyLinkButton')), 3000);
  }

  function startAnotherGame() {
    socketApi.startAnotherGame(room.name);
    console.log('Starting another game');
  }

  function leaveRoom() {
    socketApi.leaveRoom(room.name);
    console.log('Leaving room');
    setReconnectSecret({});
    backToTheLobby(false, '');
  }

  function nicknameFieldChange(e) {
    const newNickname = e.target.value;
    const filteredValue = newNickname.replace(/[^a-zA-Z0-9]/g, '');
    if (filteredValue.length > 15) return;
    if (filteredValue !== '') {
      setNickname(filteredValue);
    }
  }

  useEffect(() => {
    if (isEditingNickname) return;
    if (!player.uid || player.uid === '') return;
    if (nickname !== player.name) {
      socketApi.rename(nickname).then((result) => {
        if (result) {
          setPlayer({ name: nickname, uid: result });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingNickname]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Trying input: ', word, inputDisabledUntil);
    console.log('date now', Date.now() > inputDisabledUntil);
    if (Date.now() < inputDisabledUntil) return;
    if (word === '') return;
    disableInput(0);
    processGuess(word);
  };

  return (
    <div className="wrapper top-ad-padding">
      <div className="title-together-wrapper">
        <h1 className="title-together">CONTEXTO</h1>
        <h1 className="subtitle-together">together</h1>
      </div>
      {room?.started && won === true && (
        <div>
          <h1>{t('end.congrats')}</h1>
          <span>
            {t('attempts')}: {room?.guessList.length}
          </span>
          <button
            type="button"
            className="button-together"
            onClick={() => {
              startAnotherGame();
              setWon(false);
            }}
            disabled={room?.leader?.uid !== player.uid}
          >
            <Icons.Play />
            <span>{t('end.playAgain')}</span>
          </button>

          <button
            type="button"
            className="button-together"
            // onClick={() => {
            //   startAnotherGame();
            //   setWon(false);
            // }}
            // disabled={room?.leader?.uid !== player.uid}
          >
            <Icons.Share />
            <span>{t('end.share')}</span>
          </button>
        </div>
      )}
      {(!room || !room?.started || won === true) && (
        <div>
          <div className="nickname-wrapper">
            <Icons.Person />
            {isEditingNickname ? (
              <input
                type="text"
                className="nickname"
                placeholder={t('inputPlaceholderNickname')}
                value={nickname}
                onChange={(e) => {
                  nicknameFieldChange(e);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && isEditingNickname)
                    setIsEditingNickname(false);
                }}
                maxLength={15}
              />
            ) : (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <span
                className="nickname"
                onClick={() => setIsEditingNickname(true)}
              >
                {nickname === '' ? t('inputPlaceholderNickname') : nickname}
              </span>
            )}
            <button
              className="pen-button"
              type="button"
              onClick={() => setIsEditingNickname(!isEditingNickname)}
            >
              {!isEditingNickname && <Icons.Pencil />}
              {isEditingNickname && <Icons.Check />}
            </button>
          </div>

          <div className="ingame-room-wrapper">
            <div className="room-name">
              <div
                className="room-link"
                style={{
                  width: '60px',
                  height: '30px',
                  paddingRight: '15px',
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                {t('roomName')}:
              </div>
              <div
                style={{
                  width: '100%',
                  height: '30px',
                  paddingRight: '15px',
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                {game}
              </div>
            </div>

            <div className="room-code-wrapper">
              <div
                className="room-link"
                style={{
                  width: '60px',
                  height: '30px',
                  paddingRight: '25px',
                }}
              >
                Link:
              </div>

              <form>
                <input
                  className="link-box"
                  type="text"
                  value={link}
                  autoCapitalize="off"
                  autoComplete="off"
                  readOnly
                />
              </form>
              <button
                type="button"
                style={{ marginLeft: '10px' }}
                className="button-together narrower"
                onClick={copyLink}
              >
                {copyButtonText === t('copyLinkButton') && <Icons.Copy />}
                <span style={{ fontSize: '16px', paddingLeft: '10px' }}>
                  {copyButtonText}
                </span>
              </button>
            </div>

            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'space-evenly',
                margin: '30px 0 30px 0',
              }}
            >
              {room?.started === false && room.leader?.uid === player.uid && (
                <button
                  type="button"
                  className="button-together"
                  onClick={startGame}
                  disabled={
                    room.players.length === 0 ||
                    room.leader === undefined ||
                    !(room.leader?.uid === player.uid)
                  }
                >
                  <Icons.Play />
                  <span>{t('startGameButton')}</span>
                </button>
              )}
              <button
                type="button"
                onClick={leaveRoom}
                className="button-together"
              >
                <Icons.Leave />
                <span style={{ padding: '0px 3px' }}>
                  {t('leaveRoomButton')}
                </span>
              </button>
            </div>
            {room?.started === false && room.leader?.uid !== player.uid && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <span>
                  {t('awaiting')} {t('leader')}...
                </span>
              </div>
            )}

            <div className="players-list-wrapper">
              <h1 className="title-players">
                {t('playersList')}: {room.players.length}
              </h1>

              <div className="players-list">
                {room.players.map((p) => (
                  <div key={p?.uid} className="player">
                    <span>{p?.name}</span>
                    {p?.uid === room?.leader.uid && (
                      <span> ({t('leader')})</span>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {errorDisplay && <div className="errorMsg">{errorDisplay}</div>}
          </div>
        </div>
      )}
      {room?.started && won === false && (
        <div>
          <div className="together-button-bar-wrapper">
            <div style={{ verticalAlign: 'middle' }}>
              <button
                className={`button-alternative-together ${
                  showPlayerList ? 'active' : ''
                }`}
                type="button"
                onClick={() => setShowPlayerList(!showPlayerList)}
              >
                players
              </button>
              {/* eslint-disable-next-line react/jsx-no-bind */}
              <span
                style={{
                  marginLeft: '5px',
                  height: '100%',
                }}
              >
                {t('attempts')}: {room?.guessList ? room.guessList.length : 0}
              </span>
            </div>
            <button
              type="button"
              onClick={leaveRoom}
              className="button-alternative-together"
            >
              <Icons.LeaveSmall />
              <span style={{ padding: '0px 3px' }}>{t('leaveRoomButton')}</span>
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <input
              className="word"
              type="text"
              name="word"
              value={word}
              onChange={(e) => setWord(e.target.value)}
              placeholder="Enter a word"
              autoCapitalize="off"
              autoComplete="off"
              enterKeyHint="send"
            />
          </form>
          {errorDisplay}
          {highlight && !errorDisplay && (
            <Row
              word={highlight.lemma}
              distance={highlight.distance}
              highlight
            />
          )}
          {showPlayerList && (
            <div>
              <PlayerList players={room.players} guessList={room.guessList} />
            </div>
          )}
          {!showPlayerList && (
            <WordList
              words={room.guessList}
              highlights={[highlight?.lemma]}
              isMultiplayer
            />
          )}
        </div>
      )}
      {shownModal === 'password' && (
        <Modal
          onClose={() => setShownModal('')}
          closeOnMaskClick={false}
          closeButton={false}
        >
          <RoomPassword
            confirmValue={setPasswordInput}
            isCreating={isCreating}
            closeModal={() => {
              setShownModal('');
            }}
          />
        </Modal>
      )}
    </div>
  );
}

export default TogetherLobby;
